exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-leadership-tsx": () => import("./../../../src/pages/about/leadership.tsx" /* webpackChunkName: "component---src-pages-about-leadership-tsx" */),
  "component---src-pages-about-our-story-tsx": () => import("./../../../src/pages/about/our-story.tsx" /* webpackChunkName: "component---src-pages-about-our-story-tsx" */),
  "component---src-pages-about-why-us-tsx": () => import("./../../../src/pages/about/why-us.tsx" /* webpackChunkName: "component---src-pages-about-why-us-tsx" */),
  "component---src-pages-career-current-tsx": () => import("./../../../src/pages/career/current.tsx" /* webpackChunkName: "component---src-pages-career-current-tsx" */),
  "component---src-pages-career-working-tsx": () => import("./../../../src/pages/career/working.tsx" /* webpackChunkName: "component---src-pages-career-working-tsx" */),
  "component---src-pages-confirm-tsx": () => import("./../../../src/pages/confirm.tsx" /* webpackChunkName: "component---src-pages-confirm-tsx" */),
  "component---src-pages-contact-join-us-tsx": () => import("./../../../src/pages/contact-join-us.tsx" /* webpackChunkName: "component---src-pages-contact-join-us-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-services-technologies-tsx": () => import("./../../../src/pages/services/technologies.tsx" /* webpackChunkName: "component---src-pages-services-technologies-tsx" */),
  "component---src-pages-services-work-tsx": () => import("./../../../src/pages/services/work.tsx" /* webpackChunkName: "component---src-pages-services-work-tsx" */),
  "component---src-templates-job-offer-js": () => import("./../../../src/templates/job-offer.js" /* webpackChunkName: "component---src-templates-job-offer-js" */),
  "component---src-templates-portfolio-template-tsx": () => import("./../../../src/templates/portfolio-template.tsx" /* webpackChunkName: "component---src-templates-portfolio-template-tsx" */)
}

