import { ApolloClient, InMemoryCache } from '@apollo/client';
import fetch from 'isomorphic-fetch';

export const client = new ApolloClient({
  uri:
    process.env.GATSBY_GRAPHQL_API_URL ||
    'https://ymjeayhka1.execute-api.eu-west-1.amazonaws.com/dev/graphql',
  fetch,
  cache: new InMemoryCache(),
});
